.app {
  // max-width: 800px;
  margin: 0 auto;
}

.nav-bar {
  position: relative;
  z-index: 1;

  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0 10px;

    .nav-logo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 40px;
      color: var(--bs-color-body);

      .nav-button {
        display: inline-flex;
        margin-left: 0px;

        .logo {
          height: 40px;
        }
      }
    }

    .nav-controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      position: absolute;
      right: 1rem;

      .nav-button {
        display: inline-flex;
        margin-left: 10px;
      }
    }
  }
}

.nav-button {
  display: inline-flex;
  height: 40px;
  border: 0px solid #333;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  text-decoration: none;
  font-size: 24px;
  margin-left: 10px;
  background: none;
  color: var(--bs-color-navbar);
}

.footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  font-size: 12px;
  text-align: center;

  a {
    color: var(--bs-color-body);
  }

  .version {
    position: absolute;
    right: 30px;
  }
}
