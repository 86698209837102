@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?rgykea');
  src:  url('fonts/icomoon.eot?rgykea#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?rgykea') format('truetype'),
    url('fonts/icomoon.woff?rgykea') format('woff'),
    url('fonts/icomoon.svg?rgykea#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-to-list:before {
  content: "\e900";
}
.icon-classic-computer:before {
  content: "\e901";
}
.icon-controller-fast-backward:before {
  content: "\e902";
}
.icon-creative-commons-attribution:before {
  content: "\e903";
}
.icon-creative-commons-noderivs:before {
  content: "\e904";
}
.icon-creative-commons-noncommercial-eu:before {
  content: "\e905";
}
.icon-creative-commons-noncommercial-us:before {
  content: "\e906";
}
.icon-creative-commons-public-domain:before {
  content: "\e907";
}
.icon-creative-commons-remix:before {
  content: "\e908";
}
.icon-creative-commons-share:before {
  content: "\e909";
}
.icon-creative-commons-sharealike:before {
  content: "\e90a";
}
.icon-creative-commons:before {
  content: "\e90b";
}
.icon-document-landscape:before {
  content: "\e90c";
}
.icon-remove-user:before {
  content: "\e90d";
}
.icon-warning:before {
  content: "\e90e";
}
.icon-arrow-bold-down:before {
  content: "\e90f";
}
.icon-arrow-bold-left:before {
  content: "\e910";
}
.icon-arrow-bold-right:before {
  content: "\e911";
}
.icon-arrow-bold-up:before {
  content: "\e912";
}
.icon-arrow-down:before {
  content: "\e913";
}
.icon-arrow-left:before {
  content: "\e914";
}
.icon-arrow-long-down:before {
  content: "\e915";
}
.icon-arrow-long-left:before {
  content: "\e916";
}
.icon-arrow-long-right:before {
  content: "\e917";
}
.icon-arrow-long-up:before {
  content: "\e918";
}
.icon-arrow-right:before {
  content: "\e919";
}
.icon-arrow-up:before {
  content: "\e91a";
}
.icon-arrow-with-circle-down:before {
  content: "\e91b";
}
.icon-arrow-with-circle-left:before {
  content: "\e91c";
}
.icon-arrow-with-circle-right:before {
  content: "\e91d";
}
.icon-arrow-with-circle-up:before {
  content: "\e91e";
}
.icon-bookmark:before {
  content: "\e91f";
}
.icon-bookmarks:before {
  content: "\e920";
}
.icon-chevron-down:before {
  content: "\e921";
}
.icon-chevron-left:before {
  content: "\e922";
}
.icon-chevron-right:before {
  content: "\e923";
}
.icon-chevron-small-down:before {
  content: "\e924";
}
.icon-chevron-small-left:before {
  content: "\e925";
}
.icon-chevron-small-right:before {
  content: "\e926";
}
.icon-chevron-small-up:before {
  content: "\e927";
}
.icon-chevron-thin-down:before {
  content: "\e928";
}
.icon-chevron-thin-left:before {
  content: "\e929";
}
.icon-chevron-thin-right:before {
  content: "\e92a";
}
.icon-chevron-thin-up:before {
  content: "\e92b";
}
.icon-chevron-up:before {
  content: "\e92c";
}
.icon-chevron-with-circle-down:before {
  content: "\e92d";
}
.icon-chevron-with-circle-left:before {
  content: "\e92e";
}
.icon-chevron-with-circle-right:before {
  content: "\e92f";
}
.icon-chevron-with-circle-up:before {
  content: "\e930";
}
.icon-cloud:before {
  content: "\e931";
}
.icon-controller-fast-forward:before {
  content: "\e932";
}
.icon-controller-jump-to-start:before {
  content: "\e933";
}
.icon-controller-next:before {
  content: "\e934";
}
.icon-controller-paus:before {
  content: "\e935";
}
.icon-controller-play:before {
  content: "\e936";
}
.icon-controller-record:before {
  content: "\e937";
}
.icon-controller-stop:before {
  content: "\e938";
}
.icon-controller-volume:before {
  content: "\e939";
}
.icon-dot-single:before {
  content: "\e93a";
}
.icon-dots-three-horizontal:before {
  content: "\e93b";
}
.icon-dots-three-vertical:before {
  content: "\e93c";
}
.icon-dots-two-horizontal:before {
  content: "\e93d";
}
.icon-dots-two-vertical:before {
  content: "\e93e";
}
.icon-download:before {
  content: "\e93f";
}
.icon-emoji-flirt:before {
  content: "\e940";
}
.icon-flow-branch:before {
  content: "\e941";
}
.icon-flow-cascade:before {
  content: "\e942";
}
.icon-flow-line:before {
  content: "\e943";
}
.icon-flow-parallel:before {
  content: "\e944";
}
.icon-flow-tree:before {
  content: "\e945";
}
.icon-install:before {
  content: "\e946";
}
.icon-layers:before {
  content: "\e947";
}
.icon-open-book:before {
  content: "\e948";
}
.icon-resize-100:before {
  content: "\e949";
}
.icon-resize-full-screen:before {
  content: "\e94a";
}
.icon-save:before {
  content: "\e94b";
}
.icon-select-arrows:before {
  content: "\e94c";
}
.icon-sound-mute:before {
  content: "\e94d";
}
.icon-sound:before {
  content: "\e94e";
}
.icon-trash:before {
  content: "\e94f";
}
.icon-triangle-down:before {
  content: "\e950";
}
.icon-triangle-left:before {
  content: "\e951";
}
.icon-triangle-right:before {
  content: "\e952";
}
.icon-triangle-up:before {
  content: "\e953";
}
.icon-uninstall:before {
  content: "\e954";
}
.icon-upload-to-cloud:before {
  content: "\e955";
}
.icon-upload:before {
  content: "\e956";
}
.icon-add-user:before {
  content: "\e957";
}
.icon-address:before {
  content: "\e958";
}
.icon-adjust:before {
  content: "\e959";
}
.icon-air:before {
  content: "\e95a";
}
.icon-aircraft-landing:before {
  content: "\e95b";
}
.icon-aircraft-take-off:before {
  content: "\e95c";
}
.icon-aircraft:before {
  content: "\e95d";
}
.icon-align-bottom:before {
  content: "\e95e";
}
.icon-align-horizontal-middle:before {
  content: "\e95f";
}
.icon-align-left:before {
  content: "\e960";
}
.icon-align-right:before {
  content: "\e961";
}
.icon-align-top:before {
  content: "\e962";
}
.icon-align-vertical-middle:before {
  content: "\e963";
}
.icon-archive:before {
  content: "\e964";
}
.icon-area-graph:before {
  content: "\e965";
}
.icon-attachment:before {
  content: "\e966";
}
.icon-awareness-ribbon:before {
  content: "\e967";
}
.icon-back-in-time:before {
  content: "\e968";
}
.icon-back:before {
  content: "\e969";
}
.icon-bar-graph:before {
  content: "\e96a";
}
.icon-battery:before {
  content: "\e96b";
}
.icon-beamed-note:before {
  content: "\e96c";
}
.icon-bell:before {
  content: "\e96d";
}
.icon-blackboard:before {
  content: "\e96e";
}
.icon-block:before {
  content: "\e96f";
}
.icon-book:before {
  content: "\e970";
}
.icon-bowl:before {
  content: "\e971";
}
.icon-box:before {
  content: "\e972";
}
.icon-briefcase:before {
  content: "\e973";
}
.icon-browser:before {
  content: "\e974";
}
.icon-brush:before {
  content: "\e975";
}
.icon-bucket:before {
  content: "\e976";
}
.icon-cake:before {
  content: "\e977";
}
.icon-calculator:before {
  content: "\e978";
}
.icon-calendar:before {
  content: "\e979";
}
.icon-camera:before {
  content: "\e97a";
}
.icon-ccw:before {
  content: "\e97b";
}
.icon-chat:before {
  content: "\e97c";
}
.icon-check:before {
  content: "\e97d";
}
.icon-circle-with-cross:before {
  content: "\e97e";
}
.icon-circle-with-minus:before {
  content: "\e97f";
}
.icon-circle-with-plus:before {
  content: "\e980";
}
.icon-circle:before {
  content: "\e981";
}
.icon-circular-graph:before {
  content: "\e982";
}
.icon-clapperboard:before {
  content: "\e983";
}
.icon-clipboard:before {
  content: "\e984";
}
.icon-clock:before {
  content: "\e985";
}
.icon-code:before {
  content: "\e986";
}
.icon-cog:before {
  content: "\e987";
}
.icon-colours:before {
  content: "\e988";
}
.icon-compass:before {
  content: "\e989";
}
.icon-copy:before {
  content: "\e98a";
}
.icon-credit-card:before {
  content: "\e98b";
}
.icon-credit:before {
  content: "\e98c";
}
.icon-cross:before {
  content: "\e98d";
}
.icon-cup:before {
  content: "\e98e";
}
.icon-cw:before {
  content: "\e98f";
}
.icon-cycle:before {
  content: "\e990";
}
.icon-database:before {
  content: "\e991";
}
.icon-dial-pad:before {
  content: "\e992";
}
.icon-direction:before {
  content: "\e993";
}
.icon-document:before {
  content: "\e994";
}
.icon-documents:before {
  content: "\e995";
}
.icon-drink:before {
  content: "\e996";
}
.icon-drive:before {
  content: "\e997";
}
.icon-drop:before {
  content: "\e998";
}
.icon-edit:before {
  content: "\e999";
}
.icon-email:before {
  content: "\e99a";
}
.icon-emoji-happy:before {
  content: "\e99b";
}
.icon-emoji-neutral:before {
  content: "\e99c";
}
.icon-emoji-sad:before {
  content: "\e99d";
}
.icon-erase:before {
  content: "\e99e";
}
.icon-eraser:before {
  content: "\e99f";
}
.icon-export:before {
  content: "\e9a0";
}
.icon-eye:before {
  content: "\e9a1";
}
.icon-feather:before {
  content: "\e9a2";
}
.icon-flag:before {
  content: "\e9a3";
}
.icon-flash:before {
  content: "\e9a4";
}
.icon-flashlight:before {
  content: "\e9a5";
}
.icon-flat-brush:before {
  content: "\e9a6";
}
.icon-folder-images:before {
  content: "\e9a7";
}
.icon-folder-music:before {
  content: "\e9a8";
}
.icon-folder-video:before {
  content: "\e9a9";
}
.icon-folder:before {
  content: "\e9aa";
}
.icon-forward:before {
  content: "\e9ab";
}
.icon-funnel:before {
  content: "\e9ac";
}
.icon-game-controller:before {
  content: "\e9ad";
}
.icon-gauge:before {
  content: "\e9ae";
}
.icon-globe:before {
  content: "\e9af";
}
.icon-graduation-cap:before {
  content: "\e9b0";
}
.icon-grid:before {
  content: "\e9b1";
}
.icon-hair-cross:before {
  content: "\e9b2";
}
.icon-hand:before {
  content: "\e9b3";
}
.icon-heart-outlined:before {
  content: "\e9b4";
}
.icon-heart:before {
  content: "\e9b5";
}
.icon-help-with-circle:before {
  content: "\e9b6";
}
.icon-help:before {
  content: "\e9b7";
}
.icon-home:before {
  content: "\e9b8";
}
.icon-hour-glass:before {
  content: "\e9b9";
}
.icon-image-inverted:before {
  content: "\e9ba";
}
.icon-image:before {
  content: "\e9bb";
}
.icon-images:before {
  content: "\e9bc";
}
.icon-inbox:before {
  content: "\e9bd";
}
.icon-infinity:before {
  content: "\e9be";
}
.icon-info-with-circle:before {
  content: "\e9bf";
}
.icon-info:before {
  content: "\e9c0";
}
.icon-key:before {
  content: "\e9c1";
}
.icon-keyboard:before {
  content: "\e9c2";
}
.icon-lab-flask:before {
  content: "\e9c3";
}
.icon-landline:before {
  content: "\e9c4";
}
.icon-language:before {
  content: "\e9c5";
}
.icon-laptop:before {
  content: "\e9c6";
}
.icon-leaf:before {
  content: "\e9c7";
}
.icon-level-down:before {
  content: "\e9c8";
}
.icon-level-up:before {
  content: "\e9c9";
}
.icon-lifebuoy:before {
  content: "\e9ca";
}
.icon-light-bulb:before {
  content: "\e9cb";
}
.icon-light-down:before {
  content: "\e9cc";
}
.icon-light-up:before {
  content: "\e9cd";
}
.icon-line-graph:before {
  content: "\e9ce";
}
.icon-link:before {
  content: "\e9cf";
}
.icon-list:before {
  content: "\e9d0";
}
.icon-location-pin:before {
  content: "\e9d1";
}
.icon-location:before {
  content: "\e9d2";
}
.icon-lock-open:before {
  content: "\e9d3";
}
.icon-lock:before {
  content: "\e9d4";
}
.icon-log-out:before {
  content: "\e9d5";
}
.icon-login:before {
  content: "\e9d6";
}
.icon-loop:before {
  content: "\e9d7";
}
.icon-magnet:before {
  content: "\e9d8";
}
.icon-magnifying-glass:before {
  content: "\e9d9";
}
.icon-mail:before {
  content: "\e9da";
}
.icon-man:before {
  content: "\e9db";
}
.icon-map:before {
  content: "\e9dc";
}
.icon-mask:before {
  content: "\e9dd";
}
.icon-medal:before {
  content: "\e9de";
}
.icon-megaphone:before {
  content: "\e9df";
}
.icon-menu:before {
  content: "\e9e0";
}
.icon-message:before {
  content: "\e9e1";
}
.icon-mic:before {
  content: "\e9e2";
}
.icon-minus:before {
  content: "\e9e3";
}
.icon-mobile:before {
  content: "\e9e4";
}
.icon-modern-mic:before {
  content: "\e9e5";
}
.icon-moon:before {
  content: "\e9e6";
}
.icon-mouse:before {
  content: "\e9e7";
}
.icon-music:before {
  content: "\e9e8";
}
.icon-network:before {
  content: "\e9e9";
}
.icon-new-message:before {
  content: "\e9ea";
}
.icon-new:before {
  content: "\e9eb";
}
.icon-news:before {
  content: "\e9ec";
}
.icon-note:before {
  content: "\e9ed";
}
.icon-notification:before {
  content: "\e9ee";
}
.icon-old-mobile:before {
  content: "\e9ef";
}
.icon-old-phone:before {
  content: "\e9f0";
}
.icon-palette:before {
  content: "\e9f1";
}
.icon-paper-plane:before {
  content: "\e9f2";
}
.icon-pencil:before {
  content: "\e9f3";
}
.icon-phone:before {
  content: "\e9f4";
}
.icon-pie-chart:before {
  content: "\e9f5";
}
.icon-pin:before {
  content: "\e9f6";
}
.icon-plus:before {
  content: "\e9f7";
}
.icon-popup:before {
  content: "\e9f8";
}
.icon-power-plug:before {
  content: "\e9f9";
}
.icon-price-ribbon:before {
  content: "\e9fa";
}
.icon-price-tag:before {
  content: "\e9fb";
}
.icon-print:before {
  content: "\e9fc";
}
.icon-progress-empty:before {
  content: "\e9fd";
}
.icon-progress-full:before {
  content: "\e9fe";
}
.icon-progress-one:before {
  content: "\e9ff";
}
.icon-progress-two:before {
  content: "\ea00";
}
.icon-publish:before {
  content: "\ea01";
}
.icon-quote:before {
  content: "\ea02";
}
.icon-radio:before {
  content: "\ea03";
}
.icon-reply-all:before {
  content: "\ea04";
}
.icon-reply:before {
  content: "\ea05";
}
.icon-retweet:before {
  content: "\ea06";
}
.icon-rocket:before {
  content: "\ea07";
}
.icon-round-brush:before {
  content: "\ea08";
}
.icon-rss:before {
  content: "\ea09";
}
.icon-ruler:before {
  content: "\ea0a";
}
.icon-scissors:before {
  content: "\ea0b";
}
.icon-share-alternitive:before {
  content: "\ea0c";
}
.icon-share:before {
  content: "\ea0d";
}
.icon-shareable:before {
  content: "\ea0e";
}
.icon-shield:before {
  content: "\ea0f";
}
.icon-shop:before {
  content: "\ea10";
}
.icon-shopping-bag:before {
  content: "\ea11";
}
.icon-shopping-basket:before {
  content: "\ea12";
}
.icon-shopping-cart:before {
  content: "\ea13";
}
.icon-shuffle:before {
  content: "\ea14";
}
.icon-signal:before {
  content: "\ea15";
}
.icon-sound-mix:before {
  content: "\ea16";
}
.icon-sports-club:before {
  content: "\ea17";
}
.icon-spreadsheet:before {
  content: "\ea18";
}
.icon-squared-cross:before {
  content: "\ea19";
}
.icon-squared-minus:before {
  content: "\ea1a";
}
.icon-squared-plus:before {
  content: "\ea1b";
}
.icon-star-outlined:before {
  content: "\ea1c";
}
.icon-star:before {
  content: "\ea1d";
}
.icon-stopwatch:before {
  content: "\ea1e";
}
.icon-suitcase:before {
  content: "\ea1f";
}
.icon-swap:before {
  content: "\ea20";
}
.icon-sweden:before {
  content: "\ea21";
}
.icon-switch:before {
  content: "\ea22";
}
.icon-tablet:before {
  content: "\ea23";
}
.icon-tag:before {
  content: "\ea24";
}
.icon-text-document-inverted:before {
  content: "\ea25";
}
.icon-text-document:before {
  content: "\ea26";
}
.icon-text:before {
  content: "\ea27";
}
.icon-thermometer:before {
  content: "\ea28";
}
.icon-thumbs-down:before {
  content: "\ea29";
}
.icon-thumbs-up:before {
  content: "\ea2a";
}
.icon-thunder-cloud:before {
  content: "\ea2b";
}
.icon-ticket:before {
  content: "\ea2c";
}
.icon-time-slot:before {
  content: "\ea2d";
}
.icon-tools:before {
  content: "\ea2e";
}
.icon-traffic-cone:before {
  content: "\ea2f";
}
.icon-tree:before {
  content: "\ea30";
}
.icon-trophy:before {
  content: "\ea31";
}
.icon-tv:before {
  content: "\ea32";
}
.icon-typing:before {
  content: "\ea33";
}
.icon-unread:before {
  content: "\ea34";
}
.icon-untag:before {
  content: "\ea35";
}
.icon-user:before {
  content: "\ea36";
}
.icon-users:before {
  content: "\ea37";
}
.icon-v-card:before {
  content: "\ea38";
}
.icon-video:before {
  content: "\ea39";
}
.icon-vinyl:before {
  content: "\ea3a";
}
.icon-voicemail:before {
  content: "\ea3b";
}
.icon-wallet:before {
  content: "\ea3c";
}
.icon-water:before {
  content: "\ea3d";
}
.icon-500px-with-circle:before {
  content: "\ea3e";
}
.icon-500px:before {
  content: "\ea3f";
}
.icon-basecamp:before {
  content: "\ea40";
}
.icon-behance:before {
  content: "\ea41";
}
.icon-creative-cloud:before {
  content: "\ea42";
}
.icon-dropbox:before {
  content: "\ea43";
}
.icon-evernote:before {
  content: "\ea44";
}
.icon-flattr:before {
  content: "\ea45";
}
.icon-foursquare:before {
  content: "\ea46";
}
.icon-google-drive:before {
  content: "\ea47";
}
.icon-google-hangouts:before {
  content: "\ea48";
}
.icon-grooveshark:before {
  content: "\ea49";
}
.icon-icloud:before {
  content: "\ea4a";
}
.icon-mixi:before {
  content: "\ea4b";
}
.icon-onedrive:before {
  content: "\ea4c";
}
.icon-paypal:before {
  content: "\ea4d";
}
.icon-picasa:before {
  content: "\ea4e";
}
.icon-qq:before {
  content: "\ea4f";
}
.icon-rdio-with-circle:before {
  content: "\ea50";
}
.icon-renren:before {
  content: "\ea51";
}
.icon-scribd:before {
  content: "\ea52";
}
.icon-sina-weibo:before {
  content: "\ea53";
}
.icon-skype-with-circle:before {
  content: "\ea54";
}
.icon-skype:before {
  content: "\ea55";
}
.icon-slideshare:before {
  content: "\ea56";
}
.icon-smashing:before {
  content: "\ea57";
}
.icon-soundcloud:before {
  content: "\ea58";
}
.icon-spotify-with-circle:before {
  content: "\ea59";
}
.icon-spotify:before {
  content: "\ea5a";
}
.icon-swarm:before {
  content: "\ea5b";
}
.icon-vine-with-circle:before {
  content: "\ea5c";
}
.icon-vine:before {
  content: "\ea5d";
}
.icon-vk-alternitive:before {
  content: "\ea5e";
}
.icon-vk-with-circle:before {
  content: "\ea5f";
}
.icon-vk:before {
  content: "\ea60";
}
.icon-xing-with-circle:before {
  content: "\ea61";
}
.icon-xing:before {
  content: "\ea62";
}
.icon-yelp:before {
  content: "\ea63";
}
.icon-dribbble-with-circle:before {
  content: "\ea64";
}
.icon-dribbble:before {
  content: "\ea65";
}
.icon-facebook-with-circle:before {
  content: "\ea66";
}
.icon-facebook:before {
  content: "\ea67";
}
.icon-flickr-with-circle:before {
  content: "\ea68";
}
.icon-flickr:before {
  content: "\ea69";
}
.icon-github-with-circle:before {
  content: "\ea6a";
}
.icon-github:before {
  content: "\ea6b";
}
.icon-google-with-circle:before {
  content: "\ea6c";
}
.icon-google:before {
  content: "\ea6d";
}
.icon-instagram-with-circle:before {
  content: "\ea6e";
}
.icon-instagram:before {
  content: "\ea6f";
}
.icon-lastfm-with-circle:before {
  content: "\ea70";
}
.icon-lastfm:before {
  content: "\ea71";
}
.icon-linkedin-with-circle:before {
  content: "\ea72";
}
.icon-linkedin:before {
  content: "\ea73";
}
.icon-pinterest-with-circle:before {
  content: "\ea74";
}
.icon-pinterest:before {
  content: "\ea75";
}
.icon-rdio:before {
  content: "\ea76";
}
.icon-stumbleupon-with-circle:before {
  content: "\ea77";
}
.icon-stumbleupon:before {
  content: "\ea78";
}
.icon-tumblr-with-circle:before {
  content: "\ea79";
}
.icon-tumblr:before {
  content: "\ea7a";
}
.icon-twitter-with-circle:before {
  content: "\ea7b";
}
.icon-twitter:before {
  content: "\ea7c";
}
.icon-vimeo-with-circle:before {
  content: "\ea7d";
}
.icon-vimeo:before {
  content: "\ea7e";
}
.icon-youtube-with-circle:before {
  content: "\ea7f";
}
.icon-youtube:before {
  content: "\ea80";
}
