@import '@mdxeditor/editor/style.css';

.editor-container {
  border-radius: 5px;
  max-height: calc(100vh - 100px);
  overflow: auto;
  box-shadow: 0 0px 0px 1px;

  .mdxeditor {
    [role='toolbar'] {
      border-radius: 0;
      background-color: var(--bs-bg-body);
      // background: linear-gradient(180deg, #ccc 0%, #aaa 100%);
      // box-shadow: inset 0 5px 7px;
      border-bottom: 1px solid;
      color: var(--bs-color-body);
    }

    .editor-editable {
      background-color: var(--bs-bg-body);
      color: var(--bs-color-body);
    }
  }
}
