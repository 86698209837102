// @import 'node_modules/bootstrap/scss/_variables';

// $theme-colors: (
//   'primary': 'var(--bs-primary)',
//   'secondary': grey,
//   'success': green,
//   'info': lightblue,
//   'warning': orange,
//   'danger': red,
//   'light': #eee,
//   'dark': #333
// );

@import 'node_modules/bootstrap/scss/bootstrap';

/* Common */

html {
  min-height: 300px;
  min-width: 400px;
}

body {
  height: 100vh;
  padding: 0px 0 0;
  margin: 0px 0 0;
  background-color: var(--bs-bg-body);
  color: var(--bs-color-body);
}

.page {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
  border-radius: 10px;
  margin: 0px 20px;
  background-color: var(--bs-light);
  box-shadow: 0 0px 0px 2px;
}
