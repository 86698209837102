// Theme Sample

[class^='theme-sample-container-'] {
  border: 1px solid var(--bs-dark);
}

.theme-sample-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  .logo {
    height: 40px;
    justify-self: center;
  }
}

.theme-sample-content {
  background-color: var(--bs-bg-color);
  padding: 1rem;
  box-shadow: 0 0 0 1px;
  border-radius: 10px;
}
